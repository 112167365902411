import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import { Message } from 'element-ui'
const instance = axios.create({
    timeout: 3600000,//响应超时时间
  });
instance.defaults.withCredentials=false;//让ajax携带cookie
instance.defaults.headers.post['Content-Type'] = 'application/json'; //配置请求头
  // 添加请求拦截器
instance.interceptors.request.use(function (config) {
    if (Vue.$cookies.get('token')) {
      config.headers['token'] = Vue.$cookies.get('token') // 请求头带上token
    }
    
    return config;
  }, function (error) {
    // 对请求错误做些什么
    Message.error('“request” 请求失败');
    return Promise.reject(error );
  });

// 添加响应拦截器
instance.interceptors.response.use(function (res) {
  if (res.data.code === 402) {
    Message.error(res.data.msg);
  }
  if(res.data.code === 500 || res.data.code === 600){
    Message.error(res.data.msg);
  }
  return res;
}, function (error) {
  if (!error.response) {
    Message.error("网络连接错误，请检查网络");
    return false;
  }
  switch (error.response.status) {
    case 302:
      error.message = '错误请求：找不到url请求（后台过滤）';
      break;
    case 400:
      error.message = '错误请求：字段名称、类型前后台不一致';
      break;
    case 401:
      error.message = '未授权，请重新登录';
      break;
    case 403:
      error.message = '拒绝访问';
      break;
    case 404:
      error.message = '请求错误,未找到该资源';
      break;
    case 405:
      error.message = '请求方法未允许,可能是请求类型(get post)不一致';
      break;
    case 408:
      error.message = '请求超时';
      break;
    case 500:
      error.message = '服务器端出错';
      break;
    case 501:
      error.message = '网络未实现';
      break;
    case 502:
      error.message = '网络错误';
      break;
    case 503:
      error.message = '服务不可用';
      break;
    case 504:
      error.message = '网络超时';
      break;
    case 505:
      error.message = 'http版本不支持该请求';
      break;
    default:
      error.message = `连接错误${error.response.status}`;
  }
  // 对请求错误做些什么
  Message.error('“response” 响应失败，错误:' + error.message);
  return Promise.reject(error);
});
export default function (method, url, data = null) {
  method = method.toLowerCase();
  if (method == 'post') {
    return instance.post(url, data)
  } else if (method == 'get') {
    if (url.includes('sendVerCode')) {
      url = url + '/' + data
      return instance.get(url, {})
    }
    return instance.get(url, { params: data })
  } else if (method == 'delete') {
    return instance.delete(url, { params: data })
  }else if(method == 'put'){
    return instance.put(url,data)
  }else{
    console.error('未知的method'+method);
    return false
  }
}
//export default instance;
