<template>
  <div v-show="show" class="tip-upload">
    <img class="pdf-pic" :src="image" alt="">

    <div
      v-if="activeTab === 'Pdf2Word' || activeTab === 'Pdf2Txt' || activeTab === 'Pdf2Excel' || activeTab === 'Pdf2Img'"
      class="el-upload__text">
      点击下方按钮选择 PDF / 将 PDF 拖入此框，一次仅支持转换一个文件
    </div>
    <div v-else class="el-upload__text">
      点击下方按钮选择图片 / 将图片拖入此框，一次最多同时上传100张
    </div>

    <div class="el-upload__text">普通会员每日可免费转换5次，VIP会员不限制</div>

    <div class="excel-tip" v-if="activeTab === 'Pdf2Excel' || activeTab === 'Img2Excel'">
      注意：系统<span class="green">仅会将PDF中的表格转换至excel</span>，其他非表格内文字/图片等将不进行转换
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadTip",
  props: {
    show: Boolean,
    activeTab: {
      type: String,
      default: 'Pdf2Word'
    }
  },
  data() {
    return {
      image: require('@/assets/img/pdf2word.png'),
      list: {
        Pdf2Word: require('@/assets/img/pdf2word.png'),
        Pdf2Txt: require('@/assets/img/pdf2txt.png'),
        Pdf2Excel: require('@/assets/img/pdf2excel.png'),
        Pdf2Img: require('@/assets/img/pdf2img.png'),
        Img2Word: require('@/assets/img/img2word.png'),
        Img2Txt: require('@/assets/img/img2txt.png'),
        Img2Excel: require('@/assets/img/img2excel.png'),
        Img2Pdf: require('@/assets/img/img2pdf.png'),
      }
    }
  },
  watch: {
    activeTab(nVal) {
      this.image = this.list[nVal]
    }
  }
} 
</script>

<style scoped>
.tip-upload {
  margin-top: 248px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-pic {
  margin-bottom: 32px;
  width: 187px;
  height: 168px;
}

.excel-tip {
  margin-top: 5px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #A8A8A8;
    line-height: 25px;
    text-align: left;
    font-style: normal;
}

.green {
  color: #3CAA74;
}
</style>