<template>
  <div class="pos-wrap">
    <div class="pos-title" :class="{ 'pos-unactive': fileList.length == 0 }">文档定位</div>
    <div class="pos-input-wrap">
      <el-input :disabled="fileList.length == 0" class="pos-input" type="tel" v-model="posInput"
        :placeholder="fileList.length == 0 ? '' : '请输入页码'"></el-input>
      <div class="pos-btn" :class="{ 'pos-btn-unable': fileList.length == 0 }" @click="handleFilePos">定位</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: "UploadTip",
  props: {
    fileList: Array,
    activeTab: String
  },
  data() {
    return {
      posInput: ''
    }
  },
  methods: {
    handleFilePos() {
      if (this.fileList.length == 0)
        return;

      if (this.posInput <= 0 || this.posInput > this.fileList.length) {
        if (this.posInput > this.fileList.length) {
          this.$message.error('超过当前文档最大页码，当前文档最大页码为 ' + this.fileList.length);
        } else {
          this.$message.error('请输入正确的页码！');
        }
        return;
      }

      this.fileList.forEach(file => {
        file.selected = false;
      });
      this.fileList[this.posInput - 1].selected = true;
      Vue.set(this.fileList, this.posInput - 1, this.fileList[this.posInput - 1]);

      const element = document.getElementById(`item-${this.fileList[this.posInput - 1].page}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  },
  watch: {
    fileList(nVal) {
      if (nVal.length == 0) {
        this.posInput = '';
      }
    }
  }
} 
</script>

<style scoped>
.pos-wrap {
  margin-left: 30px;
  width: 320px;
  height: 50px;
  background: #E9F2FF;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pos-title {
  margin-left: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #0769F6;
  line-height: 25px;
  text-align: center;
  font-style: normal;
}

.pos-input-wrap {
  height: 100%;
  display: flex;
}

.pos-input {
  width: 120px;
  height: 100%;
}

.pos-btn {
  width: 58px;
  height: 100%;
  background: #0769F6;
  border-radius: 0px 10px 10px 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 25px;
  text-align: center;
  font-style: normal;
  cursor: pointer;
}

.pos-btn-unable {
  background: #CBCBCB;
}

.pos-unactive {
  color: #9BC0F5;
  cursor: default;
}

/deep/ .el-input__inner {
  background-color: #E9F2FF;
  border-radius: 0px;
  border: 0px solid #DCDFE6;
  height: 100%;

  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #0769F6;
  line-height: 25px;
  text-align: center;
  font-style: normal;
}

/deep/ .el-input.is-disabled .el-input__inner {
  background-color: #E9F2FF;
  border-color: #E9F2FF;
}

/deep/ .el-input__inner::placeholder {
  color: #9BC0F5;
}
</style>