<template>
  <el-dialog title="" :visible.sync="visible">
    <div class="view-columns">
      <div class="login-title">公 告</div>

      <div class="tip-text">{{announcement}}</div>

      <!-- <div class="btn-do-login" @click="onLogin"></div> -->
      <!-- <div class="btn-do-reg" @click="onRegister">注册</div> -->
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      announcement: ''
    }
  },
  methods: {
    init(announcement) {
      this.visible = true;
      this.$nextTick(() => {
        this.announcement = announcement;
      })   
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
  width: 814px;

}
/deep/ .el-dialog__body {
  padding: 0;
  height: 500px;
}
.view-columns {
  /* height: 50vh; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  justify-content: flex-start;
  align-items: center;
  font-size: 1.2em;
}

.login-title {
  margin-top: 52px;
  margin-bottom: 30px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 30px;
  color: #333333;
  line-height: 42px;
  text-align: center;
  font-style: normal;
}

.tip-text {
  padding: 0px 80px 80px;
  height: 250px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #333333;
    line-height: 28px;
    text-align: center;
    font-style: normal;
    overflow-y: scroll;
}

.tip-text::-webkit-scrollbar {
  display: none;
  /* Chrome 和 Safari 隐藏滚动条 */
}

.btn-do-login {
  width: 480px;
  height: 60px;
  margin-top: 50px;
  background: #086AF6;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-indent: 0;
  color: #fff;
}

.btn-do-reg {
  width: 480px;
  height: 60px;
  margin-top: 20px;
  background: #fff;
  border: 1px solid #086AF6;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-indent: 0;
  color: #086AF6;
}
</style>