<template>
  <el-dialog title="" :visible.sync="visible">
    <div class="view-columns">
      <div class="title">请选择输出模式</div>

      <div class="type-wrap">
        <div class="img-type" :class="{'img-type-active': imgType === 0}" @click="handleSelect(0)">JPG</div>
        <div class="img-type" :class="{ 'img-type-active': imgType === 1 }" @click="handleSelect(1)">PNG</div>
      </div>

      <div class="btn-do-login" @click="handleClick">生成</div>
    </div>
  </el-dialog>
</template>

<script>
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const MAX_SINGLE_IMG_COUNT = 20;

export default {
  props: {
    fileList: Array,
  },
  data() {
    return {
      visible: false,
      imgType: 0,//0:JPEG  1:PNG
      type: 0,//0 zip 1 img
    }
  },
  methods: {
    init(type) {
      this.visible = true;
      this.type = type;
      this.imgType = 0;
      this.$nextTick(() => {
      })
    },
    handleSelect(imgType) {
      this.imgType = imgType;
    },
    handleClick() {
      this.getZip();
      this.visible = false;
    },
    async getZip() {
      this.showLoading();
      let res = await this.$api.getZip({ zipType : 1});

      if (res.data.code == 0) {
        if (this.type === 0) {
          this.saveImagesAsZip(this.fileList);

        } else if (this.type === 1) {
          this.saveImagesAsSingleImage(this.fileList);
        }

        this.visible = false;
      } else {
        this.hideLoading();
        this.$message.error(res.data.msg);
      }
    },
    saveImagesAsZip(fileList) {
      // 过滤出 checked 为 true 的图片
      const selectedFiles = fileList.filter(file => file.checked);
      if (selectedFiles.length === 0) {
        this.$message.error('请选择要保存的图片');
        return;
      }
      console.log("saveImagesAsZip", selectedFiles);
      const zip = new JSZip();
      const imgPromises = [];

      selectedFiles.forEach((file, index) => {
        const { raw } = file;
        const extension = this.imgType === 1 ? 'png' : 'jpeg'; // 根据 type 决定格式
        const imageName = `${file.page}.${extension}`; // 设置图像格式扩展名
        console.log("saveImagesAsZip raw", raw);

        // 将 raw Blob 数据转为图片
        const imgPromise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const dataUrl = e.target.result;

            // 创建一个新的 Image 对象
            const img = new Image();
            img.onload = () => {
              // 创建一个 canvas 元素
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              // 设置 canvas 尺寸为图片尺寸
              canvas.width = img.width;
              canvas.height = img.height;

              // 将图片绘制到 canvas 上
              ctx.drawImage(img, 0, 0);

              // 根据 this.imgType 输出为 PNG 或 JPEG 格式
              const imageFormat = this.imgType === 1 ? 'image/png' : 'image/jpeg';
              const quality = this.imgType === 1 ? undefined : 1; // 如果是 JPEG，可以设置压缩质量

              // 将 canvas 导出为对应格式的数据 URL
              const imgDataUrl = canvas.toDataURL(imageFormat, quality);

              // 将图像数据加入到压缩包（只保存 base64 编码的部分）
              zip.file(imageName, imgDataUrl.split(',')[1], { base64: true });
              resolve();
            };
            img.onerror = (err) => reject(err);

            // 将 Data URL 加载到 Image 对象中
            img.src = dataUrl;
          };
          reader.onerror = (err) => reject(err);
          reader.readAsDataURL(raw);
        });

        imgPromises.push(imgPromise);
      });

      // 等待所有图片处理完成后生成 zip 文件
      Promise.all(imgPromises)
        .then(() => {
          zip.generateAsync({ type: 'blob' })
            .then((content) => {
              // 生成压缩包并下载
              saveAs(content, 'images.zip');

              this.hideLoading();
            });
        })
        .catch((err) => {
          console.error('Error creating zip file:', err);
        });
    },
    async saveImagesAsSingleImage(fileList) {
      console.log("saveImagesAsSingleImage", fileList);
      // 过滤出 checked 为 true 的图片
      const selectedFiles = fileList.filter(file => file.checked);

      if (selectedFiles.length > MAX_SINGLE_IMG_COUNT) {
        this.$message.error(`生成长图不能超过${MAX_SINGLE_IMG_COUNT}张`);
        this.hideLoading();
        return;
      }

      // 初始化图片合成的高度和宽度
      let totalHeight = 0;
      let maxWidth = 0;

      // 使用 for 循环逐个加载图片并计算宽高，确保顺序
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const img = new Image();
        const reader = new FileReader();

        await new Promise((resolve, reject) => {
          reader.onload = (e) => {
            img.src = e.target.result;
            img.onload = () => {
              totalHeight += img.height; // 累加图片的高度
              maxWidth = Math.max(maxWidth, img.width); // 获取最大宽度
              resolve();
            };
            img.onerror = reject;
          };

          reader.onerror = reject;
          reader.readAsDataURL(file.raw); // 读取 Blob 数据并转换为 Data URL
        });
      }

      // 创建一个 canvas 元素
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // 设置 canvas 的宽度和高度
      canvas.width = maxWidth;
      canvas.height = totalHeight;

      console.log("saveImagesAsSingleImage maxWidth", maxWidth, " totalHeight ", totalHeight);

      let currentHeight = 0; // 当前绘制位置的高度

      // 逐个绘制每张图片
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const img = new Image();
        const reader = new FileReader();

        await new Promise((resolve, reject) => {
          reader.onload = (e) => {
            img.src = e.target.result;
            img.onload = () => {
              // 绘制图片到 canvas 上
              ctx.drawImage(img, 0, currentHeight, img.width, img.height);
              currentHeight += img.height; // 更新当前绘制位置
              resolve();
            };
            img.onerror = reject;
          };

          reader.onerror = reject;
          reader.readAsDataURL(file.raw); // 读取 Blob 数据并转换为 Data URL
        });
      }

      // 根据 imgType 选择图片格式
      const imageFormat = this.imgType === 0 ? 'image/jpeg' : 'image/png';
      const imgDataUrl = canvas.toDataURL(imageFormat, this.imgType === 0 ? 0.9 : undefined); // JPEG 设置质量

      // 使用 FileSaver.js 直接下载合成的图片
      const blob = this.dataURLToBlob(imgDataUrl);
      saveAs(blob, 'merged_image.' + (this.imgType === 0 ? 'jpeg' : 'png'));

      this.hideLoading();
    },
    // 将 Data URL 转换为 Blob
    dataURLToBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }
      return new Blob([uintArray], { type: mimeString });
    },
    showLoading(text) {
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    hideLoading() {
      if (this.fullscreenLoading)
        this.fullscreenLoading.close();
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
  width: 814px;

}

/deep/ .el-dialog__body {
  padding: 0;
  height: 480px;
}

.view-columns {
  /* height: 50vh; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  justify-content: flex-start;
  align-items: center;
  font-size: 1.2em;
}

.title {
  margin-top: 100px;
  margin-bottom: 60px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 30px;
    color: #333333;
    line-height: 42px;
    text-align: center;
    font-style: normal;
}

.type-wrap {
  width: 620px;
  display: flex;
  justify-content: space-between;
}

.img-type {
  cursor: pointer;
  width: 300px;
    height: 76px;
    border: 1px solid #8A8A8A;

    font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 30px;
      color: #8A8A8A;
      line-height: 42px;
      text-align: center;
      font-style: normal;

      display: flex;
      justify-content: center;
      align-items: center;
}

.img-type-active {
  border: 1px solid #086AF6;
  color: #086AF6;
}

.btn-do-login {
  width: 480px;
  height: 60px;
  margin-top: 50px;
  background: #086AF6;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-indent: 0;
  color: #fff;
}
</style>