<template>
  <el-dialog title="" :visible.sync="visible">
    <div class="view-columns">
      <div class="login-title">万能文档转换器</div>
      <!-- <div class="register-btn" @click="handleRegister">注册账号</div> -->

      <el-tabs v-model="form.loginType">
        <el-tab-pane label="手机验证码登录" name="2">
          <div class="input-wrap">
            <el-form ref="mobileForm" :rules="rulesMobile" :model="form">
              <el-form-item prop="mobile">
                <el-input v-model="form.mobile" maxlength="11" placeholder="请输入手机号码"></el-input>
              </el-form-item>
              <el-form-item class="password-input" prop="verCode">
                <el-input v-model="form.verCode" maxlength="4" placeholder="请输入验证码"></el-input>
                <el-button :disabled="isButtonDisabled" @click="getVerificationCode" class="get-code">{{ buttonText
                  }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="用户名密码登录" name="1">
          <div class="input-wrap">
            <el-form ref="accountForm" :rules="rulesAccount" :model="form">
              <el-form-item prop="account">
                <el-input v-model="form.account" maxlength="15" placeholder="请输入用户名"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input class="password-input" show-password maxlength="16" minlength="10" v-model="form.password"
                  placeholder="请输入密码"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane> -->
      </el-tabs>

      <div class="btn-do-login" @click="onLogin">登录</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      isButtonDisabled: false,
      buttonText: '点击发送验证码',
      countdown: 60,
      form: {
        loginType: '2',
        account: '',
        password: '',
        mobile: '',
        verCode: '',
      },
      rulesAccount: {
        account: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 10, max: 16, message: '长度在 10 到 16 个字符', trigger: 'blur' }
        ],
      },
      rulesMobile: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: this.validatePhone, trigger: 'blur' }
        ],
        verCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true;
      this.form = {
        loginType: '2',
        account: '',
        password: '',
        mobile: '',
        verCode: '',
      };

      this.$nextTick(() => {
        // this.$refs['accountForm'].resetFields();
        this.$refs['mobileForm'].resetFields();
      })
    },
    onLogin() {
      if (this.form.loginType == '1') {
        if (!this.form.account) {
          this.$message.error("请输入用户名");
          return;
        }

        if (!this.form.password) {
          this.$message.error("请输入密码");
          return;
        }
      } else {
        if (!this.form.mobile) {
          this.$message.error("请输入手机号");
          return;
        }

        if (!this.form.verCode) {
          this.$message.error("请输入验证码");
          return;
        }
      }

      this.userLogin();
    },
    async userLogin() {
      let res = await this.$api.login(this.form);
      if (res.data.code == 0) {
        this.$cookies.set('token', res.data.token, '50Y');
        this.$emit('success', res.data.info);

        this.$message({
          message: '登录成功',
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.visible = false
          }
        })
      }
    },
    handleRegister() {
      this.visible = false;
      this.$emit('register');
    },
    // 手机号校验
    validatePhone(rule, value, callback) {
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (!value) {
        callback(new Error('请输入手机号'));
      } else if (!phoneRegex.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    },
    // 获取验证码
    getVerificationCode() {
      console.log("getVerificationCode");
      this.$refs.mobileForm.validateField('mobile', (valid) => {
        console.log('valid', valid, typeof valid)
        if (!valid) {
          this.sendVerCode();
        }
      });
    },
    async sendVerCode() {
      let res = await this.$api.sendVerCode(this.form.mobile);
      console.log("sendVerCode", res);
      if (res.data.code == 0) {
        this.startCountdown();
      }
    },
    // 开始倒计时
    startCountdown() {
      this.isButtonDisabled = true;
      this.buttonText = `${this.countdown}s`;
      let interval = setInterval(() => {
        this.countdown--;
        this.buttonText = `${this.countdown}s`;
        if (this.countdown === 0) {
          clearInterval(interval);
          this.countdown = 60;
          this.isButtonDisabled = false;
          this.buttonText = '点击发送验证码';
        }
      }, 1000);
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
    width: 814px;
      
}

/deep/ .el-dialog__body {
  padding: 0;
  height: 500px;
}
.login-dialog {
  width: 814px;
    height: 500px;
}
.view-columns {
  /* height: 50vh; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  justify-content: flex-start;
  align-items: center;
  font-size: 1.2em;
}

.login-title {
  margin-top: 52px;
  margin-bottom: 30px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 30px;
    color: #333333;
    line-height: 42px;
    text-align: center;
    font-style: normal;
}

.register-btn {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 28px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #086AF6;
    line-height: 28px;
    text-align: center;
    font-style: normal;
}

.login-username {
  width: 60%;
  height: 2.8em;
  border: 1px solid #bebebe;
  border-radius: 4px;
  margin-top: 1.8em;
  text-indent: 1em;
  font-size: 1.2em;
}

.login-password {
  width: 60%;
  height: 2.8em;
  border: 1px solid #bebebe;
  border-radius: 4px;
  margin-top: 1.8em;
  text-indent: 1em;
  font-size: 1.2em;
}

.btn-do-login {
  width: 480px;
  height: 60px;
  margin-top: 25px;
  background: #086AF6;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-indent: 0;
  color: #fff;
}

.btn-do-reg {
  position: absolute;
  bottom: 20px;
  right: 30px;
  text-decoration: underline;
}

.input-wrap {
  margin-top: 30px;
  width: 480px;
}

.password-input {
  position: relative;
}

.get-code {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  border: 0;
  background: transparent;
  font-size: 16px;
  color: #333333;
  min-width: 152px;
}

/deep/ .el-tabs__nav-wrap::after {
  height: 0px;
}

/deep/ .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}

/deep/ .el-tabs__item {
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    font-style: normal;
}

/deep/ .el-tabs__active-bar {
  width: 40px !important;
  left: 50px;
  bottom: 6px;
}

/deep/ .el-input__inner {
  height: 60px;
  background: #F6F6F6;
  font-size: 16px;
  color: #9A9A9A;
}

/deep/ .el-form-item {
  margin-bottom: 20px;
}

/deep/ .el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  color: #333333;
  cursor: not-allowed;
  background-image: none;
  background-color: transparent;
  border-color: transparent;
}
</style>