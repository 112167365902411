<template>
  <el-dialog title="" :visible.sync="visible">
    <div class="view-columns">
      <div class="login-title">关于我们</div>
      <div class="title">
        奇信万能文档转换器为杭州奇治信息技术股份有限公司开发的一款文档转换工具，能够轻松实现PDF转Word、Excel、Txt、图片；图片转Word、Excel、Txt、Pdf等多种文件格式之间的转换。<br><br>杭州奇治信息技术股份有限公司成立于2013年7月，坐落于杭州国家高新（滨江）区。
        奇治信息是浙江省软件企业，国家高新技术企业。多年来致力于产品研发和技术积累，拥有45项软件著作权、8项国家技术发明专利等知识产权。2017年起连续被杭州高新区（滨江）评为“瞪羚企业”。
        奇治信息专注智能文档识别、企业SaaS服务、在线棋类教育等多个领域。</div>
      <div class="title txt-w">客服微信：kefuhao7 工作时间：周一至周五 9:00-18:00</div>
      <div class="title txt-w">联系方式：15167112350</div>
      <div class="title txt-w">公司地址：杭州市滨江区江陵路2028号星耀城C座1006室</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,     
    }
  },
  methods: {
    init() {
      this.visible = true;
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
  width: 814px;

}
/deep/ .el-dialog__body {
  padding: 0;
  height: 600px;
}
.view-columns {
  /* height: 50vh; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  justify-content: flex-start;
  align-items: center;
  font-size: 1.2em;
}

.login-title {
  margin-top: 52px;
  margin-bottom: 20px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 30px;
    color: #333333;
    line-height: 42px;
    text-align: center;
    font-style: normal;
}

.title {
  margin: 0 80px;
  margin-top: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  line-height: 28px;
  text-align: center;
  font-style: normal;
}

.txt-w {
  width: 600px;

  display: flex;
  justify-content: flex-start;
}

.btn-do-login {
  width: 480px;
  height: 60px;
  margin-top: 30px;
  background: #086AF6;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-indent: 0;
  color: #fff;
}

.input-wrap {
  margin-top: 42px;
  width: 677px;
  height: 298px;
  background: #F7F7F7;
}
</style>