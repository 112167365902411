<template>
  <el-dialog title="" :visible.sync="visible">
    <div class="view-columns">
      <div class="viplist-wrap">
        <div class="viplist-title-wrap">
          <div class="account">{{ userInfo.account }}</div>
          <img class="userlevel-img"
            :src="userInfo.vipLevel == 0 ? require('@/assets/img/vip0.png') : require('@/assets/img/vip1.png')"
            alt="VIP">
          <div v-if="userInfo.vipLevel > 0">到期日期：{{ userInfo.vipEndTime }}</div>
          <div class="account-logout" @click="handleLogout">退出登录</div>
        </div>

        <div class="viplist-content">
          <div class="viplist-content-radius">
            <div class="viplist-content-title">VIP会员</div>
          </div>


          <div class="viplist-item-wrap">
            <div class="viplist-item" :class="item.select ? 'item-select' : ''" v-for="(item, index) in vipList"
              :key="index" @click="handleSelectItem(item)">
              <img v-if="item.supFlag" class="suprice" src="../assets/img/suprice.png" alt="">
              <div class="item-title-wrap">
                <div class="viplist-item-title">{{ item.vipName }}</div>
                <div class="viplist-item-des">不限制转换次数</div>
              </div>
              <div class="item-price-wrap">
                <div class="viplist-item-rmb">¥</div>
                <div class="viplist-item-price">{{ item.money }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pay-wrap">
        <div class="pay-title">支付方式:</div>
        <div class="pay-mode">
          <div class="pay-item" :class="payChannel==2?'pay-item-active' : ''" @click="handlePayChannel(2)">
            <img class="pay-item-icon" src="../assets/img/alipay.png" alt="">
            <div class="pay-item-title">支付宝支付</div>
          </div>
          <div class="pay-item" :class="payChannel == 1 ? 'pay-item-active' : ''" @click="handlePayChannel(1)">
            <img class="pay-item-icon" src="../assets/img/wxpay.png" alt="">
            <div class="pay-item-title">微信支付</div>
          </div>
        </div>

        <div class="pay-price">
          <div class="pay-price-text">支付</div>
          <div class="pay-price-rmb">¥</div>
          <div class="pay-price-title">{{ selectItem.money }}</div>
        </div>

        <div class="pay-code">
          <canvas id="qrcode"></canvas>

          <div class="pay-time">
            <img class="pay-time-icon" src="../assets/img/timepay.png" alt="">
            <div class="pay-time-title">{{ expireTime }}</div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import QRious from 'qrious';

export default {
  data() {
    return {
      visible: false,
      userInfo: {
        account: '',
        vipLevel: 0,
        dayUseCount: 0,
        vipEndTime: '',
      },
      vipList: [],
      selectItem: {
        id: '',
        money: '',
        vipName: '',
        supFlag: 1
      },
      payChannel: 1,
      expireTime: '',
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        console.log("visible false");
        this.stopCountdown();
        this.stopOrderCountdown();
      }
    }
  },
  beforeDestroy() {
    console.log("beforeDestroy");
  },
  methods: {
    init(userInfo) {
      this.visible = true;
      console.log("init", userInfo);
      this.userInfo = userInfo;
      this.getVipList();
    },
    handleLogout() {
      this.$confirm('是否退出登录?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$cookies.remove('token');
          this.$emit('logout');
          this.$message({
            message: '退出成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.visible = false
            }
          })
        }).catch(() => {

        });
    },
    handleSelectItem(item) {
      this.selectItem.select = false;
      item.select = true;
      this.selectItem = item;      
      this.buyVip();
    },
    handlePayChannel(payChannel) {
      this.payChannel = payChannel;
      this.buyVip();
    },
    async getVipList() {
      let res = await this.$api.getVipList();

      if (res.data.code == 0) {
        this.vipList = res.data.vipList;
        if (this.vipList.length > 0) {
          this.vipList[0].select = true;
          this.vipList[0].supFlag = 1;
          this.selectItem = this.vipList[0];
          this.buyVip();
        }
      }
    },
    getExistOrder() {
      let res = localStorage.getItem(`order${this.$H.userInfo.uid}-${this.selectItem.id}-${this.payChannel}`);
      if (res) {
        res = JSON.parse(atob(res));
        const now = new Date().getTime();
        let expireTime = new Date(res.data.expireTime).getTime();
        const distance = expireTime - now;
        
        console.log("getExistOrder distance", distance);
        if (distance < 0) {
          return null;
        }

        return res;
      }
      
      return null;
    },
    async buyVip() {
      console.log("buyVip selectItem", this.selectItem);
      console.log("buyVip payChannel", this.payChannel);
      let res = this.getExistOrder();
      console.log("buyVip getExistOrder res", res);
    
      if (!res) {
        res = await this.$api.buyVip({ buyVipId: this.selectItem.id, payChannel: this.payChannel });
        console.log("buyVip res", res);
      }

      if (res.data.code == 0) {
        localStorage.setItem(`order${this.$H.userInfo.uid}-${this.selectItem.id}-${this.payChannel}`, btoa(JSON.stringify(res)));

        this.selectItem.orderId = res.data.orderId;
        this.generateQRCode(res.data.codeUrl);
        this.calcExpireTime(res.data.expireTime);
        this.startCheckOrderStatus();
      } else {
        this.hideQRCode();
        this.stopCountdown();
        this.expireTime = '';
      }
    },
    generateQRCode(codeUrl) {
      const qrCodeCanvas = document.getElementById('qrcode');
      if (qrCodeCanvas) {
        qrCodeCanvas.style.display = 'block'; // 显示二维码
        const qr = new QRious({
          element: qrCodeCanvas,
          value: codeUrl,
          size: 172 // 调整二维码大小
        });
      }
    },
    hideQRCode() {
      const qrCodeCanvas = document.getElementById('qrcode');
      if (qrCodeCanvas) {
        qrCodeCanvas.style.display = 'none'; // 从DOM中移除二维码
      }
    },
    setExpireTime(expireTime) {
      const now = new Date().getTime();
      const distance = expireTime - now;

      if (distance < 0) {
        this.stopCountdown();
        this.expireTime = "支付时间已过期";
        this.buyVip();
        return;
      }

      const hours = String(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
      const minutes = String(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      const seconds = String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(2, '0');

      this.expireTime = `支付剩余时间：${hours}:${minutes}:${seconds}`;
    },
    calcExpireTime(expireTime) {
      this.stopCountdown();
      let time = new Date(expireTime).getTime();
      this.setExpireTime(time);
      this.interval = setInterval(() => {
        this.setExpireTime(time);
      }, 1000);
    },
    stopCountdown() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
    startCheckOrderStatus() {
      this.stopOrderCountdown();
      this.intervalOrder = setInterval(() => {
        this.checkOrderStatus();
      }, 1000);
    },
    stopOrderCountdown() {
      if (this.intervalOrder) {
        clearInterval(this.intervalOrder);
      }
    },
    async checkOrderStatus() {
      let res = await this.$api.checkOrderStatus({ orderId: this.selectItem.orderId });

      if (res.data.code === 0 && res.data.orderStatus === 1) {
        localStorage.removeItem(`order${this.$H.userInfo.uid}-${this.selectItem.id}-${this.payChannel}`);
        this.stopOrderCountdown();
        this.$emit('purchase');
        this.$message({
          message: '支付成功',
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.visible = false
          }
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
  width: 814px;
}

/deep/ .el-dialog__body {
  padding: 0;
  height: 430px;
}
.view-columns {
  position: relative;
  /* height: 50vh; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;

  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1.2em;
}

.viplist-wrap {
  width: 500px;
}

.viplist-title-wrap {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
    height: 78px;
    background: #086AF6;

    font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 17px;
      text-align: left;
      font-style: normal;
}

.account {
    margin-left: 30px;
  margin-right: 9px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 25px;
    text-align: left;
    font-style: normal;
    max-width: 200px;
}

.userlevel-img {
  margin-right: 19px;
  width: 100px;
  height: 32px;
}

.account-logout {
  position: absolute;
  right: 20px;
}

.viplist-content {
  position: relative;
  height: 353px;
  width: 100%;
}

.viplist-content-title {
  margin-top: 12px;
  font-family: Arial, Arial;
    font-weight: 900;
    font-size: 20px;
    color: #424242;
    line-height: 28px;
    text-align: center;
    font-style: normal;
}

.viplist-content-radius {
      z-index: 1;
  position: absolute;
  top: -14px;
  width: 186px;
    height: 46px;
    background: #FFFFFF;
    border-radius: 10px 20px 14px 14px;
}

.viplist-item-wrap {
position: relative;
  top: 30px;
  margin-left: 40px;
  z-index: 1;
  max-height: 270px;
  overflow-y: scroll;
  scrollbar-width: none;
  /* width: 100%; */
  /* height: auto; */
  padding-top: 25px;
}

.viplist-item-wrap::-webkit-scrollbar {
  display: none;
  /* Chrome 和 Safari 隐藏滚动条 */
}

.viplist-item {
  cursor: pointer;
  position: relative;
  padding-left: 31px;
  padding-right: 24px;
  margin-bottom: 20px;
  width: 365px;
    height: 100px;
    background: #F9F9F9;
    border-radius: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-select {
  background: linear-gradient(180deg, #FFE7B4 0%, #FFE59B 100%);
}

.suprice {
  z-index: 1;
  position: absolute;
  left: 0;
  top: -14px;
  width: 110px;
  height: 28px;
}

.item-title-wrap {
  display: flex;
  flex-direction: column;
}

.viplist-item-title {
  margin-bottom: 6px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #4D0818;
    line-height: 28px;
    text-align: left;
    font-style: normal;
}

.viplist-item-des {
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #4D0818;
    line-height: 20px;
    text-align: left;
    font-style: normal;
}

.item-price-wrap {
  display: flex;
}

.viplist-item-rmb {
  position: relative;
  top: 7px;
    right: 15px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #4D0818;
    line-height: 33px;
    text-align: left;
    font-style: normal;
}

.viplist-item-price {
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 48px;
    color: #4D0818;
    line-height: 33px;
    text-align: left;
    font-style: normal;
}

.pay-wrap {
  flex: 1;
  padding-top: 26px;
  padding-left: 33px;
  background: #F4F8FF;
  height: calc(100% - 26px);
}

.pay-title {
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}

.pay-mode {
  display: flex;
  margin-top: 6px;
  margin-bottom: 20px;
}

.pay-item {
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 118px;
    height: 28px;
    border: 1px solid #DBDBDB;

  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    text-align: left;
    font-style: normal;
}

.pay-item-active {
  border: 1px solid #086AF6;
}

.pay-item-icon {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.pay-price {
  width: 275px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #4D0818;
    line-height: 25px;
    text-align: left;
    font-style: normal;
}

.pay-price-text {
  position: relative;
  top: 3px;
  left: -5px;
}

.pay-price-rmb {
  font-size: 24px;
}

.pay-price-title {
  font-size: 56px;
  line-height: 46px;
}

.pay-code {
  margin-top: 20px;
  margin-left: -40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pay-paycode {
  width: 172px;
  height: 172px;
}

.pay-time {
  /* margin-top: 10px; */
  display: flex;
  align-items: center;
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #DE5245;
    line-height: 20px;
    text-align: left;
    font-style: normal;
}

.pay-time-icon {
  margin-left: 2px;
  width: 16px;
  height: 16px;
}
</style>