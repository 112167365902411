<template>
  <el-dialog title="" :visible.sync="visible">
    <div class="view-columns">
      <div class="tip-text">请登录账号使用该功能，注册用户享有每日免费转换次数</div>

      <div class="btn-do-login" @click="onLogin">登录</div>
      <!-- <div class="btn-do-reg" @click="onRegister">注册</div> -->
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    init() {
      this.visible = true;
    },
    onLogin() {
      this.visible = false;
      this.$emit('login');
    },
    onRegister() {
      this.visible = false;
      this.$emit('register');
    }
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
  width: 814px;

}
/deep/ .el-dialog__body {
  padding: 0;
  height: 480px;
}
.view-columns {
  /* height: 50vh; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  justify-content: flex-start;
  align-items: center;
  font-size: 1.2em;
}

.tip-text {
  margin-top: 103px;
  padding: 0 167px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 30px;
    color: #333333;
    line-height: 42px;
    text-align: center;
    font-style: normal;
}

.btn-do-login {
  width: 480px;
  height: 60px;
  margin-top: 50px;
  background: #086AF6;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-indent: 0;
  color: #fff;
}

.btn-do-reg {
  width: 480px;
  height: 60px;
  margin-top: 20px;
  background: #fff;
  border: 1px solid #086AF6;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-indent: 0;
  color: #086AF6;
  cursor: pointer;
}
</style>