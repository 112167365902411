<template>
  <div id="app">
    <Sidebar ref="sidebar" @tab-selected="handleTabSelection" />
    <div class="main-content">
      <Pdf2Word v-show="activeTab == 'Pdf2Word'" :activeTab="activeTab" tabName="Pdf2Word" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <Pdf2Word v-show="activeTab == 'Pdf2Txt'" :activeTab="activeTab" tabName="Pdf2Txt" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <Pdf2Word v-show="activeTab == 'Pdf2Excel'" :activeTab="activeTab" tabName="Pdf2Excel"
        @notify="handleNotification" @userinfo="handleNotification" @purchase="handleNotification" />
      <Pdf2Img v-show="activeTab == 'Pdf2Img'" :activeTab="activeTab" tabName="Pdf2Img" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <Img2Word v-show="activeTab == 'Img2Word'" :activeTab="activeTab" tabName="Img2Word" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <Img2Word v-show="activeTab == 'Img2Txt'" :activeTab="activeTab" tabName="Img2Txt" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
      <Img2Word v-show="activeTab == 'Img2Excel'" :activeTab="activeTab" tabName="Img2Excel"
        @notify="handleNotification" @userinfo="handleNotification" @purchase="handleNotification" />
      <Img2Pdf v-show="activeTab == 'Img2Pdf'" :activeTab="activeTab" tabName="Img2Pdf" @notify="handleNotification"
        @userinfo="handleNotification" @purchase="handleNotification" />
    </div>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar.vue'
import Pdf2Word from './components/Pdf/Pdf2Word.vue'
import Pdf2Img from './components/Pdf/Pdf2Img.vue'
import Img2Word from './components/Img/Img2Word.vue'
import Img2Pdf from './components/Img/Img2Pdf.vue'

export default {
  name: 'App',
  components: {
    Sidebar,
    Pdf2Word,
    Pdf2Img,
    Img2Word,
    Img2Pdf
  },
  data() {
    return {
      activeTab: 'Pdf2Word', // 初始状态
    };
  },
  mounted() {
    // console.log("App mounted")
    localStorage.clear();
  },
  methods: {
    handleTabSelection(selectedTab) {
      console.log('Selected tab:', selectedTab);
      console.log(window.screen.height, window.screen.width)
      this.activeTab = selectedTab;
    },
    handleNotification(notification) {
      console.log('Received notification:', notification);
      if (notification.type == 'login') {
        this.$refs.sidebar.showTipLogin();
      } else if (notification.type == 'userinfo') {
        this.$refs.sidebar.loginSuccess();
      } else if (notification.type == 'purchase') {
        this.$refs.sidebar.showTipPurchase();
      } 
    }
  },
}
</script>

<style>
#app {
  display: flex;
  height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 30px 24px 0px;
}
</style>