<template>
  <div class="sidebar-collapses">
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item name="1">
        <template slot="title">
          <div class="collapse-title">
            <img class="icon" src="@/assets/img/icon-pdf.png" mode="scaleToFill" />
            PDF转其他
          </div>
        </template>
        <el-tabs v-model="activeTab" @tab-click="handleTabClick" tab-position="left">
          <el-tab-pane label="PDF转WORD" name="Pdf2Word"></el-tab-pane>
          <el-tab-pane label="PDF转文字" name="Pdf2Txt"></el-tab-pane>
          <el-tab-pane label="PDF转EXCEL" name="Pdf2Excel"></el-tab-pane>
          <el-tab-pane label="PDF转图片" name="Pdf2Img"></el-tab-pane>
        </el-tabs>
      </el-collapse-item>
      <el-collapse-item>
        <template slot="title">
          <div class="collapse-title">
            <img class="icon" src="@/assets/img/icon-img.png" mode="scaleToFill" />
            图片转其他
          </div>
        </template>
        <el-tabs v-model="activeTab" @tab-click="handleTabClick" tab-position="left">
          <el-tab-pane label="图片转WORD" name="Img2Word"></el-tab-pane>
          <el-tab-pane label="图片转文字" name="Img2Txt"></el-tab-pane>
          <el-tab-pane label="图片转EXCEL" name="Img2Excel"></el-tab-pane>
          <el-tab-pane label="图片转PDF" name="Img2Pdf"></el-tab-pane>
        </el-tabs>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>


<script>
export default {
  name: 'SidebarTabs',
  props: {
    tabSelected: Function,
  },
  data() {
    return {
      activeTab: 'Pdf2Word', // 默认选中的 Tab
      activeNames: ['1']
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    handleTabClick(tab) {
      this.$emit('tab-selected', tab);
    }
  }
}
</script>

<style scoped>

.sidebar-collapses {
  z-index: 11;
  height: 39vh;
  overflow-y: scroll;
  padding-bottom: 15px;
}

.sidebar-collapses::-webkit-scrollbar {
    width: 6px;
    height: 0px;
}

.sidebar-collapses:hover::-webkit-scrollbar {
  z-index: 0;
  width: 6px;
  height: 0px;
}

.sidebar-collapses::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 6px;
  height: 0px;
  background: #b4bccc
}

.sidebar-collapses::-webkit-scrollbar-corner,
.sidebar-collapses::-webkit-scrollbar-track {
  background: #fff
}

.sidebar-collapses::-webkit-scrollbar-track-piece {
  background: #fff;
  width: 6px;
  height: 0px;
}

.collapse-title {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    line-height: 25px;
    text-align: left;
    font-style: normal;
}
.icon {
  margin-right: 12px;
  width: 28px;
  height: 28px;
}

/deep/ .el-tabs--left{
  overflow: hidden;
    /* width: 222px; */
}

/deep/ .el-tabs__nav-wrap::after{
  width: 0;
  background: #fff;
}

/deep/ .el-tabs__item {
  padding: 0;
  width: 222px;
  height: 42px;
  border-radius: 10px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #707070;
  line-height: 56px;
  text-align: center;
  font-style: normal;
  position: relative;
}

/deep/ .el-tabs--left .el-tabs__item.is-left {
  display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

/deep/ .el-tabs__item:hover {
  color: #0769F6;
  cursor: pointer;
}

/deep/ .el-tabs__item.is-active {
  color: #fff;
  background: #0769F6;
  height: 56px;
  font-size: 18px;
}

/deep/ .el-tabs__item {
  padding: 0 60px;

}

/deep/ .el-tabs--left .el-tabs__item.is-left {
  text-align: center;
}

/deep/ .el-tabs--left .el-tabs__nav-wrap.is-left::after,
.el-tabs--left .el-tabs__nav-wrap.is-right::after,
.el-tabs--right .el-tabs__nav-wrap.is-left::after,
.el-tabs--right .el-tabs__nav-wrap.is-right::after {
  width: 0px;
}

/deep/ .el-tabs__active-bar {
  background-color: transparent;
}

/deep/ .el-collapse-item__wrap,
/deep/ .el-collapse-item__header {
  border-bottom: 0;
  width: 222px;
  justify-content: center;
}

/deep/ .el-collapse-item__arrow {
  margin-left: 42px;
      font-size: 14px;
        color: #414141;
}

/deep/ .el-collapse-item__content {
  padding-bottom: 0;
}
</style>