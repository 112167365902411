import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import api from './http/api.js';  // 拦截器
import VueCookies from 'vue-cookies';
import "amfe-flexible"
import "./assets/css/element.css"
// import '@/utils/rem.js'

Vue.config.productionTip = false
Vue.prototype.$api = api;
Vue.prototype.$H = {};

Vue.use(ElementUI);
Vue.use(VueCookies);

new Vue({
  render: h => h(App),
}).$mount('#app')
